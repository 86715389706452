<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="6" class="d-none d-md-flex login__image justify-end" style="padding-top: 280px; padding-left: 200px;"> 
          <img style="height: 400px; top: 100px;" svg-inline :src="image" alt="Ingresar">
        </CCol>
        <CCol cols="12" md="6" class="my-4 my-sm-8 my-md-0" style="margin-top: 120px !important">
          <div class="login__container">
            <div class="login__inner">
              <div class="login__image">
                <img src="main_logo.jpg" style="width: 300px; margin: 0 0px;" alt="Logo Oscuro">
              </div>
              <div class="login__header pt-5">
                  <h5 style="font-size: 16px; font-weight: bold;">{{user.first_name}} {{user.last_name}}</h5>
                  <br />
                  <h5 style="font-size: 14px;">Bienvenido/a, para confirmar el registro de tu cuenta, ingresa un correo electrónico y una contraseña.</h5>
                  <br />
              </div>
              <div class="login__subscribe d-flex justify-space-between align-center pb-8">
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="email"
                      label="Correo Electrónico (Usuario)" 
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="password"
                      label="Contraseña"
                      type="password"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="password_2"
                      label="Confirmar Contraseña"
                      type="password"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CButton type="button" color="primary" style="width: 100%" @click="login">CONFIRMAR REGISTRO</CButton>
                  </CCol>
                </CRow>
              </div>
              <div class="login__actions d-flex justify-space-between py-2">
                  <div class="login__remind" style="font-size: 12px;">
                  </div>
                  <div class="login__recovery-password" style="width: 100%">
                      <!-- <a href="/#/forget" class="font-weight-regular px-1" style="font-size: 12px; float: right !important;">¿Olvidaste tu contraseña?</a> -->
                  </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import router from '../../../router/index'
import login from '../../../services/login';
import ws from '../../../services/general';
import store from '../../../store'
import axios from 'axios'

export default {
  name: 'LoginLink',
  data: function () {
      return {
          code: '',
          email: '',
          password: '',
          password_2: '',
          image: '',
          user: false
      }
  },
  mounted: async function() {
    this.code = await this.$route.params.code;

    let response_login = await login.getUserByCode(this.code); 

    if(response_login.type == "success"){
      this.user = response_login.data;

      if(this.user.total_logins == 0){
        this.email = this.user.email;
        this.password = '12345678';
        this.password_2 = '12345678';
      }
      else{
        let params = {
          code: this.code
        };

        let response = await login.forceLogin(params);

        if(response.type == "success"){
          let data = response.data;

          localStorage.setItem("domain", "demo");
          localStorage.setItem("token", data.api_token);
          localStorage.setItem("role", data.role);

          axios.defaults.headers.common['domain'] = "demo";
          axios.defaults.headers.common['api-token'] = data.api_token;
          
          if(response.data.role == "administrator"){
            window.location.href = "/#/home"; return;
          }
          else{
            if(response.data.role == "supplier"){
              window.location.href = "/#/welcome"; return;
            }
            else{
              window.location.href = "/#/404"; return;
            } 
          }
        }
        else{
          alert(response.message);
        }
      }
    }

    let response = await ws.getParameters(); 

    if(response.type == "success"){
      let parameters = response.data;

      let param1 = this.$findElementByName(parameters, "LOGIN_IMAGE");
      this.image = param1.value;
    }
  },
  methods: {
    login: async function(){
      if(this.email == ""){
        alert("El correo electrónico es obligatorio.");
        return;
      }

      if(this.password == ""){
        alert("La contraseña es obligatoria.");
        return;
      }

      if(this.password != this.password_2){
        alert("Las contraseñas no coinciden.");
        return;
      }

      let params = {
        code: this.code,
        email: this.email,
        password: this.password,
        device_name: navigator.userAgent
      };

      let response = await login.loginByCode(params);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("domain", "demo");
        localStorage.setItem("token", data.api_token);
        localStorage.setItem("role", data.role);

        axios.defaults.headers.common['domain'] = "demo";
        axios.defaults.headers.common['api-token'] = data.api_token;
        
        if(response.data.role == "administrator"){
          window.location.href = "/#/home"; return;
        }
        else{
          if(response.data.role == "supplier"){
            window.location.href = "/#/welcome"; return;
          }
          else{
            window.location.href = "/#/404"; return;
          } 
        }
      }
      else{
        alert(response.message);
      }
    },
  }
}
</script>
